import React from 'react';
import Layout from '../../components/layout';
import ElevatePage from '../../components/elevate';
// import { useQueryParam } from 'use-query-params';
import SEO from '../../components/seo';
// import { FormModal } from '../../components/elevate/ElevateModal/FormModal';
const elevate = () => {
    // const [hideAccessList] = useQueryParam('no-access-list');

    return (
        <Layout>
            <SEO
                title="Elevate | J Clarke Fitness"
                description="Elevate is an easy-to-use workout tracker that can be used at home or in the gym and for any workout routine. Whether you’re new to lifting weights or highly experienced, Elevate helps fast-track your results by allowing you to log your workouts effortlessly and keep track of key insights."
            />
            <ElevatePage />
            {/* {!hideAccessList && <FormModal />} */}
        </Layout>
    );
};
export default elevate;
